












































import ProviderCategoryForm from '@/components/provider/category/ProviderCategoryForm.vue';
import ProviderUtils, { ProviderCategory } from '@/utils/ProviderUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { ProviderCategoryForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<ProviderCategory>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    try {
      const result = await ProviderUtils.api.category.get(
        this.$route.params?.key
      );
      this.item = ProviderUtils.category.generateDefault(result);
      (this.$refs.form as any).resetValidation();
    } catch (error) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();
          this.item = await ProviderUtils.api.category.update(this.item);
          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.edit', [
              this.$tc('provider.category.label'),
            ]),
            type: 'success',
          });
        } catch (error) {
          this.$toast.push({
            text: this.$t('error.edit', [this.$tc('provider.category.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },

    async handleDelete() {
      try {
        this.state.errors = undefined;
        this.state.delete = true;
        await ProviderUtils.api.category.remove(this.$route.params?.key);
        this.$toast.push({
          text: this.$t('success.delete', [
            this.$tc('provider.category.label'),
          ]),
          type: 'success',
        });
        this.$router.replace({ name: 'provider-category-list' });
      } catch (error) {
        this.$toast.push({
          text: this.$t('error.delete', [this.$tc('provider.category.label')]),
          type: 'error',
        });
        this.state.errors = error; // TODO: handle errors
      } finally {
        this.state.delete = false;
      }
    },
  },
});
